.App {
  /*font-family: 'Raleway', sans-serif;*/
  text-align: left;
  line-height: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column
}

.paragraphs-policy {
  line-height: 2;
  text-align: justify;
}

body, html {
  /*font-family: 'Raleway', sans-serif;*/
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

input[type=text],
input[type=date],
select,
label {
  /*font-family: 'Raleway', sans-serif;*/
}
.form {
  padding-top: 5px;
  padding-bottom: 5px;
}

input[type=text],
input[type=date],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  border-color: #000000;
}

.custom-select{
  margin-top: 9px;
}

input[type=submit]{
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-div {
  border-radius: 10px;
  background-color: #f2f2f2;
  padding: 20px;
  margin-Top: -150;
  margin-Right: 30
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.paragraphs-citas {
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 800px){
  .sliderGallery {
      margin-top: 12;
  }
}

@media (max-width: 575px){
  .logo {
    /*width: 88px;*/
    height: 36px;
  }
}
