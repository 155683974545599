.centerMyDiv{
    margin-bottom: 170px;
    margin-top: 170px;
}

.titlePaymentOk{
    margin-bottom: 15px;
    margin-top: 25px;
    color: #198754;
}

.btn-outline-success:hover {
    color: rgb(255, 255, 255);
    background-color: #198754;
    border-color: #000000; /*set the color you want here*/
}